// 首页-员工
import {
  USER
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 在职员工数量查询
export async function getStaffStatistics(params) {
  return request(`${USER}/homePage/getStaffStatistics`, METHOD.GET, params)
}

// 人事异动分析统计图表
export async function changePersonnel(params) {
  return request(`${USER}/homePage/changePersonnel`, METHOD.GET, params)
}

// 档案待审核列表
export async function listArchivesPerfect(params) {
  return request(`${USER}/homePage/listArchivesPerfect`, METHOD.GET, params)
}

// 入职登记表待审核
export async function entryFormExamList(params) {
  return request(`${USER}/homePage/entryFormExamList`, METHOD.GET, params)
}

// 即将入职
export async function upcomingEntry(params) {
  return request(`${USER}/homePage/upcomingEntry`, METHOD.GET, params)
}

// 即将转正
export async function upcomingOfficial(params) {
  return request(`${USER}/homePage/upcomingOfficial`, METHOD.GET, params)
}

// 待离职
export async function upcomingLeave(params) {
  return request(`${USER}/homePage/upcomingLeave`, METHOD.GET, params)
}

// 即将生日
export async function upcomingBirthdate(params) {
  return request(`${USER}/homePage/upcomingBirthdate`, METHOD.GET, params)
}

// 即将周年
export async function upcomingAnniversary(params) {
  return request(`${USER}/homePage/upcomingAnniversary`, METHOD.GET, params)
}

