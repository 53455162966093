// 首页-考勤
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'

// 考勤-出勤率
export async function attendanceDayPercent(params) {
  return request(`${ATTENDACE}/homepage/attendanceDayPercent`, METHOD.GET, params)
}

// 考勤-日平均工时
export async function attendanceAvgDayWokHours(params) {
  return request(`${ATTENDACE}/homepage/attendanceAvgDayWokHours`, METHOD.GET, params)
}

// 查询本考勤周期出勤统计
export async function currentPeriodAttendanceStatistics(params) {
  return request(`${ATTENDACE}/homepage/currentPeriodAttendanceStatistics`, METHOD.GET, params)
}

// 获取所有没有考勤组的员工
export async function staffNotInGroupNotIgnore(params) {
  return request(`${ATTENDACE}/homepage/staffNotInGroupNotIgnore`, METHOD.GET, params)
}

// 查询某日迟到、早退、缺卡、旷工、请假、出差、外出、加班员工
export async function getDayAttendanceStaffList(params) {
  return request(`${ATTENDACE}/homepage/getDayAttendanceStaffList`, METHOD.GET, params)
}
